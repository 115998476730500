.LoadingIndicator {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9998;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    background-size: 100px;

    &.local {
        position: absolute;
        z-index: 1029;
    }

    .title {
        //margin-bottom: 15px;
    }

    .spinner {
        height:35px;
        width:35px;
        margin:0 auto;
        z-index: 1000;
        -webkit-animation: rotation .9s infinite linear;
        -moz-animation: rotation .9s infinite linear;
        -o-animation: rotation .9s infinite linear;
        animation: rotation .9s infinite linear;
        border-left:6px solid rgba(0,174,239,.15);
        border-right:6px solid rgba(0,174,239,.15);
        border-bottom:6px solid rgba(0,174,239,.15);
        border-top:6px solid rgba(0,174,239,.8);
        border-radius:100%;
    }

    @-webkit-keyframes rotation {
        from {-webkit-transform: rotate(0deg);}
        to {-webkit-transform: rotate(359deg);}
    }
    @-moz-keyframes rotation {
        from {-moz-transform: rotate(0deg);}
        to {-moz-transform: rotate(359deg);}
    }
    @-o-keyframes rotation {
        from {-o-transform: rotate(0deg);}
        to {-o-transform: rotate(359deg);}
    }
    @keyframes rotation {
        from {transform: rotate(0deg);}
        to {transform: rotate(359deg);}
    }
}