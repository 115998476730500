@import 'src/theme.scss';

.ApplicationTable {
    header {
        background-color: $contentColor !important;
    }

    .ApplicationTableFilter {
        * {
            //background-color: $contentColor;
        }
    }
}

