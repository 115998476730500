$info: #2CADCD;
$contentColor: #f5f5f5;

.koop-mode {
    $primary: #007E32;
    --bs-primary-rgb: 0,126,50;
    --bs-primary: #007E32;

    @import "bootstrap/scss/bootstrap";
}

.cpp-mode {
    $primary: #005091;
    --bs-primary-rgb: 0,80,145;
    --bs-primary: #005091;

    @import "bootstrap/scss/bootstrap";
}

@import "bootstrap";