.CustomTitleFilterForm {

    width: min-content;
    min-width: 50%;

    .form-control,
    .form-select {
        max-width: none;
    }

    .modal-content {
        //min-width: 100%;
        min-width: 100%;
    }
}