@import 'src/theme.scss';

.koop-mode .MyTabs .tabCard {
    &:hover {
        color: var(--bs-primary) !important;
    }
    &.active {
        color: var(--bs-primary) !important;
    }
    &:hover::before {
        background: var(--bs-primary) !important;
    }
    &.active::before {
        background: var(--bs-primary) !important;
    }
}

.cpp-mode .MyTabs .tabCard {
    &:hover {
        color: var(--bs-primary) !important
    }
    &.active {
        color: var(--bs-primary) !important;
    }
    &::before:hover {
        background: var(--bs-primary) !important;
    }
    &.active::before {
        background: var(--bs-primary) !important;
    }
}

.MyTabs {

    $clr: #E2E2E2;

    display: flex;
    border-left: 1px solid $clr;

    /* Card container */
    .tabCard {
        background: transparent;
        cursor: pointer;

        color: black !important;

        &:hover {
            font-weight: bold;
        }

        //dot
        &:before {
            content: "";
            position: absolute;
            width: 11px;
            height: 11px;
            background: $clr;
            border-radius: 999px;
            top: 0.5rem;
            cursor: pointer;
            left: -6px;
        }

        &.active {
            font-weight: bold;
        }
    }
}