.TitleRegisterImportErrorAlertPage {
    .alertHeaderWarning {
        color: #836b2f;
    }
    .alertHeaderError {
        color: #831f29;
    }
    .longText {
        max-height: 60vh;
        overflow-y: auto;
    }
}
