@import 'src/theme.scss';

.koop-mode .Header  .button-action {
  border-color: darken(#007E32, 10%) !important;
}

.cpp-mode .Header .button-action {
  border-color: darken(#005091, 10%) !important;
}

.Header {
  .logo {
    position: absolute;
    top: 0;
    bottom: 0;

    & + * {
      margin-left: 96px;
    }
  }

}