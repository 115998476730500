@import 'src/theme.scss';
@import '~bootstrap/scss/bootstrap';
@import './components/form/form.scss';
@import './components/table/myTable.scss';
@import '~react-bootstrap-typeahead/css/Typeahead';
@import '~react-bootstrap-typeahead/css/Typeahead.bs5';
@import './src/pages/TitleRegister/title-register.scss';
@import './src/pages/TitleRegister/import/title-register-import-error-alert.scss';
@import './src/pages/TitleRegister/tables/TitleRegisterTable.scss';

.App {
}

.large-confirmation {
    max-width: 650px !important;
}

.btn.big-icon-btn {
    font-size: 1.25rem !important;
    vertical-align: middle;
}
.pointer{
    cursor: pointer;
}
