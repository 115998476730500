.CustomFilterForm {

    width: auto;
    min-width: 90%;

    .form-control,
    .form-select {
        max-width: none;
    }

    .modal-content {
        //min-width: 100%;
        min-width: 100%;
    }
}